import React from "react";
import { graphql, Link } from "gatsby";
import { Box } from "rebass/styled-components";
import Layout from "../components/Layout";
import Container from "../components/Container";

export default class TermsPage extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: rooms } = data.allWordpressWpMphbRoomType;

    return (
      <Layout>
        {rooms && (
          <>
            <Container>
              <h1>Všeobecné podmínky</h1>

              <Box as="ul" sx={{ listStyle: "none", p: 0 }}>
                {rooms.map(({ node: room }) => {
                  const link = room.acf.terms_page.replace(
                    "https://cms.chaloupkakteraexistuje.cz",
                    ""
                  );

                  return (
                    <li key={room.title}>
                      <Link
                        to={link}
                      >{`Všeobecné podmínky "${room.title}"`}</Link>
                    </li>
                  );
                })}
              </Box>
            </Container>
          </>
        )}
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query TermsQuery {
    allWordpressWpMphbRoomType(sort: { fields: menu_order, order: ASC }) {
      edges {
        node {
          ...RoomTypesListFields
          acf {
            terms_page
          }
        }
      }
    }
  }
`;
